@import url('https://fonts.googleapis.com/css2?family=Titillium+Web:wght@700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Kanit:wght@600&family=Titillium+Web:wght@700&display=swap');

*{
  padding: 0;
  margin: 0;
}


.App {
  text-align: center;
}

body {
  font-family: var(--textFont) !important;
  background-color: white !important;
  color: black !important;
}

@media (prefers-color-scheme: dark){
  body {
    background-color: black !important;
    color: #fff !important;
  }
}

:root{
  --mainColor: #FD3D33;
  --mainColorLight: #fe5148;
  --secondaryColor: #13292A;
  --textColor: aliceblue;
  --logoColor: yellow;

  --textFont: 'Kanit', sans-serif;
}

header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 80px;
  padding: 0 2 rem;
  background-color: var(--mainColor);
  color: var(--textColor);
  border-radius: 30px;
  margin: 30px;
  
  
 
}

.logo {
  margin-left: 2rem;
  color: var(--textColor);
  text-decoration: none;
  font-family: var(--textFont);
  font-size: 30px;
  color: var(--logoColor);
}

.logo:hover{
  color: var(--secondaryColor)
}

nav a{
  margin: 0 0.5rem;
  color: var(--textColor);
  text-decoration: none;
  font-family: var(--textFont);
  font-size: 20px;
  
  
}

nav a:hover{
  color: var(--secondaryColor);
  
}

header .nav-btn {
  padding: 5px;
  cursor: pointer;
  background: transparent;
  border: none;
  outline: none;
  color: var(--textColor);
  visibility: hidden;
  opacity: 0;
  font-size: 1.8rem;
}



@media only screen and (max-width: 1200px) {
  header .nav-btn {
    visibility: visible;
    opacity: 1;
  }

  header nav{
    position: fixed;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 1.5rem;
    background-color: var(--mainColor);
    transition: 1s;
    transform: translateY(-100vh);
    z-index: 99;
  }

  header .responsive_nav{
    transform: none;

  }

  nav .nav-close-btn {
    position: absolute;
    top: 2rem;
    right: 2rem;
  }

  nav a{
    font-size: 1.5rem;
  }

}

.textBlock {
  margin-left: auto;
  margin-right: auto;
  max-width: 60vw;
  min-width: 400px;
  font-size: 18pt;
  margin-bottom: 30px;
}

.quelle {
  font-size: 9pt;
  margin-bottom: 10px;
}

.quellen {
  list-style-type: none;
}

.link {
  text-decoration: underline;
  color:#99BBDD;
  
}

.title{
  margin-bottom: 50px;
  font-size: 28pt;
}

.svgMap{
  margin-left: auto;
  margin-right: auto;
  display: flex;
  justify-content: center;
  align-content: center;
  margin-top: 20px;
  width: 90vw;
  max-height: 600px;
}

path{
  fill:white;
  stroke: black;
  pointer-events: all;
}

.linkUSA path{
  fill: #FD3D33;
}

.linkRussland path{
  fill: #FD3D33;
}

.linkUkraine path{
  fill: #FD3D33;
}

.linkUSA:hover path{
  fill: #575656;
}

.linkRussland:hover path{
  fill: #575656;
}

.linkUkraine:hover path{
  fill: #575656;
}

.carousel {
  max-width: 900px;
  margin-left: auto;
  margin-right: auto;
}

.textImage{
  max-width: 60vw;
  min-width: 400px;
  margin-bottom: 30px;
}

.slideshowContainer{
  margin-bottom: 15px;
}

.dateInfos{
  max-width: 150px;
  margin-left: auto;
  margin-right: 50px;
  margin-bottom: 20px;
}

.dateInfos p{
  margin-bottom: 0;
  font-style: italic;
  font-weight: lighter;
}

iframe {
  margin: 20px 0;
}
